// THIS FILE IS AUTOMATICALLY GENERATED BY SPACETIMEDB. EDITS TO THIS FILE
// WILL NOT BE SAVED. MODIFY TABLES IN RUST INSTEAD.

// @ts-ignore
import { __SPACETIMEDB__, AlgebraicType, ProductType, BuiltinType, ProductTypeElement, SumType, SumTypeVariant, DatabaseTable, AlgebraicValue, ReducerEvent, Identity, Address, ClientDB, SpacetimeDBClient } from "@clockworklabs/spacetimedb-sdk";
// @ts-ignore
import { StDbVector2 } from "./st_db_vector_2";

export class Elements extends DatabaseTable
{
	public static db: ClientDB = __SPACETIMEDB__.clientDB;
	public static tableName = "Elements";
	public id: number;
	public path: string;
	public width: number;
	public height: number;
	public position: StDbVector2;

	public static primaryKey: string | undefined = "id";

	constructor(id: number, path: string, width: number, height: number, position: StDbVector2) {
	super();
		this.id = id;
		this.path = path;
		this.width = width;
		this.height = height;
		this.position = position;
	}

	public static serialize(value: Elements): object {
		return [
		value.id, value.path, value.width, value.height, StDbVector2.serialize(value.position)
		];
	}

	public static getAlgebraicType(): AlgebraicType
	{
		return AlgebraicType.createProductType([
			new ProductTypeElement("id", AlgebraicType.createPrimitiveType(BuiltinType.Type.U32)),
			new ProductTypeElement("path", AlgebraicType.createPrimitiveType(BuiltinType.Type.String)),
			new ProductTypeElement("width", AlgebraicType.createPrimitiveType(BuiltinType.Type.I32)),
			new ProductTypeElement("height", AlgebraicType.createPrimitiveType(BuiltinType.Type.I32)),
			new ProductTypeElement("position", StDbVector2.getAlgebraicType()),
		]);
	}

	public static fromValue(value: AlgebraicValue): Elements
	{
		let productValue = value.asProductValue();
		let __Id = productValue.elements[0].asNumber();
		let __Path = productValue.elements[1].asString();
		let __Width = productValue.elements[2].asNumber();
		let __Height = productValue.elements[3].asNumber();
		let __Position = StDbVector2.fromValue(productValue.elements[4]);
		return new this(__Id, __Path, __Width, __Height, __Position);
	}

	public static *filterById(value: number): IterableIterator<Elements>
	{
		for (let instance of this.db.getTable("Elements").getInstances())
		{
			if (instance.id === value) {
				yield instance;
			}
		}
	}

	public static findById(value: number): Elements | undefined
	{
		return this.filterById(value).next().value;
	}

	public static *filterByPath(value: string): IterableIterator<Elements>
	{
		for (let instance of this.db.getTable("Elements").getInstances())
		{
			if (instance.path === value) {
				yield instance;
			}
		}
	}

	public static *filterByWidth(value: number): IterableIterator<Elements>
	{
		for (let instance of this.db.getTable("Elements").getInstances())
		{
			if (instance.width === value) {
				yield instance;
			}
		}
	}

	public static *filterByHeight(value: number): IterableIterator<Elements>
	{
		for (let instance of this.db.getTable("Elements").getInstances())
		{
			if (instance.height === value) {
				yield instance;
			}
		}
	}


}

export default Elements;
