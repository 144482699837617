import { useEffect, useState } from "react";
import Elements from "../../module_bindings/elements";
import { StdbToViewportCoords } from "../../utility/ConvertCoordinates";

const useFetchElement = (stdbInitialized: Boolean, setIsLoading: Function) => {
  const [elements, setElements] = useState<Elements[]>();

  useEffect(() => {
    if (!stdbInitialized) return;

    const newElements = Elements.all();

    newElements.forEach((e) => {
      let coords = StdbToViewportCoords(e.position.x,e.position.y);
      e.position = {x: coords.x, y: coords.y}
    });

    setElements(newElements);

    setIsLoading(false);
  }, [stdbInitialized]);

  return elements;
};

export default useFetchElement;
