export const ViewportToStdbCoords = (x: number, y: number) : {x: number; y: number;} =>{ 
    let vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    let vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

    let newY = y * (1080/vh);
    let newX = x * (1920/vw);

    return {
        x: newX,
        y: newY,
    };
};

export const StdbToViewportCoords = (x: number, y: number) : {x: number; y: number;} =>{
    let vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    let vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

    let newY = y * (vh/1080);
    let newX = x * (vw/1920);

    return {
        x: newX,
        y: newY,
    };
};

