import ReactDOM from "react-dom/client";
import "./index.css";
import { App } from "./App";
import "./styles/reset.css";
import "./styles/fonts.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(<App />);
