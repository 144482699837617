import { useEffect } from "react";
import Elements from "../../module_bindings/elements";
import { Identity } from "@clockworklabs/spacetimedb-sdk";
import { StdbToViewportCoords } from "../../utility/ConvertCoordinates";

export const useElementsEvents = (stdbIdentity?: Identity) => {
  useEffect(() => {
    if (!stdbIdentity) return;

    Elements.onUpdate((oldElement, newElement, reducerEvent) => {
      const component = document.getElementById(oldElement.id.toString());

      if (
        !component ||
        stdbIdentity?.toHexString() ===
          reducerEvent?.callerIdentity.toHexString()
      )
        return;

      let coords = StdbToViewportCoords(newElement.position.x, newElement.position.y);
      component.style.setProperty(
        "transform",
        "translate(" +
          coords.x.toString() +
          "px, " +
          coords.y.toString() +
          "px)"
      );
    });
  }, [stdbIdentity]);
};
