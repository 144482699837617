// THIS FILE IS AUTOMATICALLY GENERATED BY SPACETIMEDB. EDITS TO THIS FILE
// WILL NOT BE SAVED. MODIFY TABLES IN RUST INSTEAD.

// @ts-ignore
import { __SPACETIMEDB__, AlgebraicType, ProductType, BuiltinType, ProductTypeElement, SumType, SumTypeVariant, DatabaseTable, AlgebraicValue, ReducerEvent, Identity, Address, ClientDB, SpacetimeDBClient } from "@clockworklabs/spacetimedb-sdk";

export class Heartbeat extends DatabaseTable
{
	public static db: ClientDB = __SPACETIMEDB__.clientDB;
	public static tableName = "Heartbeat";
	public id: number;
	public time: BigInt;

	public static primaryKey: string | undefined = "id";

	constructor(id: number, time: BigInt) {
	super();
		this.id = id;
		this.time = time;
	}

	public static serialize(value: Heartbeat): object {
		return [
		value.id, value.time
		];
	}

	public static getAlgebraicType(): AlgebraicType
	{
		return AlgebraicType.createProductType([
			new ProductTypeElement("id", AlgebraicType.createPrimitiveType(BuiltinType.Type.U32)),
			new ProductTypeElement("time", AlgebraicType.createPrimitiveType(BuiltinType.Type.I64)),
		]);
	}

	public static fromValue(value: AlgebraicValue): Heartbeat
	{
		let productValue = value.asProductValue();
		let __Id = productValue.elements[0].asNumber();
		let __Time = productValue.elements[1].asBigInt();
		return new this(__Id, __Time);
	}

	public static *filterById(value: number): IterableIterator<Heartbeat>
	{
		for (let instance of this.db.getTable("Heartbeat").getInstances())
		{
			if (instance.id === value) {
				yield instance;
			}
		}
	}

	public static findById(value: number): Heartbeat | undefined
	{
		return this.filterById(value).next().value;
	}

	public static *filterByTime(value: BigInt): IterableIterator<Heartbeat>
	{
		for (let instance of this.db.getTable("Heartbeat").getInstances())
		{
			if (instance.time === value) {
				yield instance;
			}
		}
	}


}

export default Heartbeat;
