import { createContext, useEffect, useState } from "react";
import useFetchElements from "./stdb/hooks/useFetchElements";
import IconGithub from "./assets/icons/github.svg";
import IconDiscord from "./assets/icons/discord.svg";
import IconYoutube from "./assets/icons/youtube.svg";
import IconTwitter from "./assets/icons/twitter.svg";
import IconTwitch from "./assets/icons/twitch.svg";
import "./App.css";
import useStDB from "./stdb/useStDB";
import { MoveableComponent } from "./components/MoveableComponent";
import { useElementsEvents } from "./stdb/hooks/useElementsEvents";
import { useHeartbeatEvents } from "./stdb/hooks/useHeartbeatEvents";
import { Identity } from "@clockworklabs/spacetimedb-sdk";
import AddWaitlistReducer from "./module_bindings/add_waitlist_reducer";

export const App: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [stdbInitialized, setStdbInitialized] = useState<boolean>(false);
  const [identity, setIdentity] = useState<Identity>();
  const [waitlistVisible, setWaitlistVisible] = useState<boolean>(false);
  const [email, setEmail] = useState<string>();

  useStDB(setStdbInitialized, setIdentity);

  useElementsEvents(identity);
  useHeartbeatEvents(identity);

  const newElements = useFetchElements(stdbInitialized, setIsLoading);

  function toggleWaitlistModal(trash:any) {
    setWaitlistVisible(!waitlistVisible);
  };

  function handleChange(event: any) {
    setEmail(event.target.value)
  };

  function addWaitlist(event: any) {
    event.preventDefault();
    if(email != undefined){
      AddWaitlistReducer.call(email);
      setEmail("");
      setWaitlistVisible(!waitlistVisible);
      alert("Successfuly added email to waitlist");
    }
  };
  
  useEffect(() => {
    var phrases = [
      "Let's get Pogly",
      "Jump in",
      "Away we go",
      "Get started",
      "Check it out",
      "It's on Github",
      "Awww yea",
      "Pogtastic",
      "Wicked Sick",
      "Start Pogging",
      "Time to build",
      "Simplify your overlay",
      "Let's get collaborative"];
    var rngPhrase = Math.floor(Math.random()*phrases.length);
    var element = document.getElementById("btn");
    element!.innerHTML = phrases[rngPhrase];
  }, []);
    
  return (
    <div className="card">
      {!isLoading && newElements && identity ? (
        newElements.map((e) => {
          return <MoveableComponent key={e.id} element={e} />;
        })
      ) : (
        <></>
      )}
      <div className="header">
        <div className="logo">
          <a href="#"></a>
        </div>
        <div className="social">
          <a
            href="https://youtube.com/@poglyapp"
            title="Youtube"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="icon" src={IconYoutube} />
          </a>
          <a
            href="https://x.com/poglyapp"
            title="X (Twitter)"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="icon" src={IconTwitter} />
          </a>
          <a
            href="https://www.twitch.tv/poglygg"
            title="Twitch"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="icon" src={IconTwitch} />
          </a>
          <a
            href="https://discord.gg/CXCj2TGS6b"
            title="Discord"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="icon" src={IconDiscord} />
          </a>
          <a
            href="https://github.com/poglyapp"
            title="GitHub"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="icon" src={IconGithub} />
          </a>
        </div>
      </div>
      <div className="content">
        <div className="title-holder">
          <h1>
            {/*<strong>Pogly</strong> Standalone<small style={{fontSize:10,verticalAlign:"super"}}>(beta)</small> has arrived 🎉*/}
            <strong>Pogly</strong> Standalone has arrived 🎉
          </h1>
          <p>
            Real-time collaborative stream overlay powered by{" "}
            <a
              href="https://spacetimedb.com/"
              title="SpacetimeDB"
              target="_blank"
              rel="noopener noreferrer"
            >
              SpacetimeDB
            </a>
            .
          </p>
        </div>
        <a 
          href="https://github.com/PoglyApp/pogly-standalone"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="cta" id="btn"></div>
        </a>
      </div>
      <div className="container">
        <div className="waitlist">
          <a style={{cursor:"pointer"}} onClick={toggleWaitlistModal}>Want more? Sign up for the <strong>Cloud</strong> waitlist.</a>
          {waitlistVisible ? (
          <><div className="break" />
            <form onSubmit={addWaitlist}>
              <input style={{borderRadius:50, border:"none", textAlign:"center", padding:"2px", marginRight:"4px", outline:"none"}} type="email" placeholder="your@email.com" value={email} onChange={handleChange} />
              <button style={{cursor:"pointer"}} type="submit" value="submit">Submit</button>
            </form>
          </>
          ) : (
          <>
            <div className="break" />
            <div id="waitlistSuccess"></div>
          </>
          )}
        </div>
      </div>
      <div className="footer">
        <span>
          Made with 💜 by Chippy &{" "}
          <a
            href="https://twitter.com/Dynrothe"
            style={{ textDecoration: "underline" }}
          >
            Dynny
          </a>
        </span>
      </div>
    </div>
  );
};
