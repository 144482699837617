import { useEffect, useState } from "react";
import DraggableCore from "react-draggable";
import Elements from "../module_bindings/elements";
import { updatePosition } from "../stdb/reducers/updatePosition";

interface IProp {
  element: Elements;
}

export const MoveableComponent = (Props: IProp) => {
  const [position, setPosition] = useState<any>();

  useEffect(() => {
    setPosition({ x: Props.element.position.x, y: Props.element.position.y });
  }, []);

  const onDrag = (event: any) => {
    setPosition({ x: event.clientX - 16, y: event.clientY - 16 });
    updatePosition(Props.element.id, { x: event.clientX, y: event.clientY });
  };

  return (
    <DraggableCore
      onDrag={onDrag}
      position={{ x: position?.x, y: position?.y }}
    >
      <img
        className="fade"
        key={Props.element.id.toString()}
        id={Props.element.id.toString()}
        style={{ position: "absolute" }}
        src={Props.element.path}
        width={32}
        height={32}
        draggable={false}
      />
    </DraggableCore>
  );
};
