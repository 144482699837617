import { useEffect } from "react";
import Heartbeat from "../../module_bindings/heartbeat";
import { Identity } from "@clockworklabs/spacetimedb-sdk";

export const useHeartbeatEvents = (stdbIdentity?: Identity) => {
  useEffect(() => {
    if (!stdbIdentity) return;

    Heartbeat.onUpdate((oldElement, newElement, reducerEvent) => {
      //console.log(newElement.time);
    });
  }, [stdbIdentity]);
};
