import StDbVector2 from "../../module_bindings/st_db_vector_2";
import UpdateElementPositionReducer from "../../module_bindings/update_element_position_reducer";
import { ViewportToStdbCoords } from "../../utility/ConvertCoordinates";

export const updatePosition = (
    elementId: number,
    position: StDbVector2
) => {
    let coords = ViewportToStdbCoords(position.x, position.y);
    UpdateElementPositionReducer.call(elementId,{x: coords.x,y: coords.y});
};