// THIS FILE IS AUTOMATICALLY GENERATED BY SPACETIMEDB. EDITS TO THIS FILE
// WILL NOT BE SAVED. MODIFY TABLES IN RUST INSTEAD.

// @ts-ignore
import { __SPACETIMEDB__, AlgebraicType, ProductType, BuiltinType, ProductTypeElement, SumType, SumTypeVariant, DatabaseTable, AlgebraicValue, ReducerEvent, Identity, Address, ClientDB, SpacetimeDBClient } from "@clockworklabs/spacetimedb-sdk";

export class StDbVector2 extends DatabaseTable
{
	public static db: ClientDB = __SPACETIMEDB__.clientDB;
	public static tableName = "StDbVector2";
	public x: number;
	public y: number;

	public static primaryKey: string | undefined = undefined;

	constructor(x: number, y: number) {
	super();
		this.x = x;
		this.y = y;
	}

	public static serialize(value: StDbVector2): object {
		return [
		value.x, value.y
		];
	}

	public static getAlgebraicType(): AlgebraicType
	{
		return AlgebraicType.createProductType([
			new ProductTypeElement("x", AlgebraicType.createPrimitiveType(BuiltinType.Type.I32)),
			new ProductTypeElement("y", AlgebraicType.createPrimitiveType(BuiltinType.Type.I32)),
		]);
	}

	public static fromValue(value: AlgebraicValue): StDbVector2
	{
		let productValue = value.asProductValue();
		let __X = productValue.elements[0].asNumber();
		let __Y = productValue.elements[1].asNumber();
		return new this(__X, __Y);
	}

}

export default StDbVector2;
